import request from "@/utils/request";
import { getUploadUrlBaseURL } from "@/utils/util";

export function httpUseCustom(params) {
  return request({
    url: "/api/platform/user/custom",
    method: "post",
    data: params,
  });
}

export function httpCompanyCustom(params) {
  return request({
    url: "api/platform/company/custom",
    method: "post",
    data: params,
  });
}
// /atd/warningSetting/PlatformApi/method/accountList
export function httpGetAccounts(params) {
  return request({
    url: "/api/ads/getAccounts",
    method: "post",
    data: params,
  });
}

// /platform/api/modelapi/Atd_Conn/libraryList
export function httpLibraryList(params) {
  return request({
    url: "/api/ads/library/list",
    method: "post",
    data: params,
  });
}
// 创建日期的接口
export function httpLibraryGetAllDate(params) {
  return request({
    url: "api/ads/library/get_all_date",
    method: "post",
    data: params,
  });
}
//

export function httpFacebookWriterAdd(params) {
  return request({
    url: `/api/ads/facebook/writer/add`,
    method: "post",
    data: params,
  });
}
// 创意报表
export function httpGetAppInfo(params) {
  return request({
    url: `api/ads/app/getAppInfo`,
    method: "post",
    data: params,
  });
}
//文件上传
export function httpUploadFile(row, type, url, onUploadProgress) {
  let formData = new FormData();
  formData.append("file", row);
  formData.append("type", type);
  let requests = {
    baseURL: getUploadUrlBaseURL() + "/interface/",
    url: url,
    method: "post",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  };
  if (onUploadProgress != null) {
    requests["onUploadProgress"] = function (progressEvent) {
      onUploadProgress(progressEvent);
    };
  }
  return request(requests);
}

// 自动登录接口
export function httpUserAuthUrl(params) {
  return request({
    url: "api/ads/user/auth/url",
    method: "get",
    params: params,
  });
}

//  searchReport3rd
export function httpSearchReport3rd(params) {
  return request({
    url: "/api/open-api/report/3rd/search",
    method: "post",
    data: params,
  });
}

//step2 tp5 需要修改
export function httpFormatFacebookTargetCountry() {
  return request({
    url: `/api/facebook/application/format_facebook_target_country`,
    method: "post",
  });
}

export function httpAdvertiser() {
  return request({
    url: "/advertiser/list",
    method: "post",
  });
}

export function httpCompareDetail(params) {
  return request({
    url: "api/report/aggregation/compare/detail",
    method: "post",
    data: params,
  });
}

export function httpCompareNewDetail(params) {
  return request({
    url: "api/report/aggregation/compare/new/detail",
    method: "post",
    data: params,
  });
}

export function httpCompareReportCharts(params) {
  return request({
    url: "api/report/aggregation/compare/reportCharts",
    method: "post",
    data: params,
  });
}

export function httpCompareNewReportCharts(params) {
  return request({
    url: "api/report/aggregation/compare/new/reportCharts",
    method: "post",
    data: params,
  });
}

export function httpGetAdsAccountCostAnalysis(params) {
  return request({
    url: "/api/ads/account/cost/analysis",
    method: "post",
    data: params,
  });
}

export function httpGetAdsAccountCostAnalysisByDate(params) {
  return request({
    url: "/api/report/userData/getAdsAccountCostAnalysisByDate",
    method: "post",
    data: params,
  });
}

export function httpDownloadAdsAccountCostAnalysis(params) {
  return request({
    url: "/api/report/userData/downloadAdsAccountCostAnalysis",
    method: "post",
    data: params,
  });
}

export function httpClearCatch(params) {
  return request({
    url: "api/report/aggregation/clear/report?t=",
    method: "get",
    params: params,
  });
}

// 创建模版的部分开始开发

export function httpCreatorList() {
  return request({
    url: "/api/ads/template/userList",
    method: "post",
  });
}

export function httpCreateTemplate(params) {
  return request({
    url: "/api/ads/google/create/createTemplate",
    method: "post",
    data: params,
  });
}

export function httpSaveTemplate(params) {
  return request({
    url: "/api/ads/google/create/saveTemplate",
    method: "post",
    data: params,
  });
}

export function httpGetTemplateData(params) {
  return request({
    url: "/api/ads/google/create/getTemplateData",
    method: "post",
    data: params,
  });
}

export function httpSubmitTemplateData(params) {
  return request({
    url: "/api/ads/google/create/submitTemplateData",
    method: "post",
    data: params,
  });
}

export function httpCreateTemplateParams(params) {
  return request({
    url: "/api/ads/google/create/templateParams",
    method: "post",
    data: params,
  });
}

//获取模版列表
export function httpTemplateList(params) {
  return request({
    url: "api/ads/template/list",
    method: "post",
    data: params,
  });
}

// 复制模版

export function httpTemplateCopy(params) {
  return request({
    url: "/api/ads/template/copy",
    method: "post",
    data: params,
  });
}

// 更新模版状态

export function httpTemplateUpStatus(params) {
  return request({
    url: "api/ads/template/updStatus",
    method: "post",
    data: params,
  });
}

// 模版下面的dataList

export function httpTemplateDataList(params) {
  return request({
    url: "/api/ads/template/dataList",
    method: "post",
    data: params,
  });
}

export function httpLogList(params) {
  return request({
    url: "/api/ads/operation/log/list",
    method: "post",
    data: params,
  });
}

export function httpTemplateDelete(params) {
  return request({
    url: "/api/ads/template/delete ",
    method: "post",
    data: params,
  });
}

export function httpTemplateEditName(params) {
  return request({
    url: "api/ads/template/editName",
    method: "post",
    data: params,
  });
}

export function httpTemplateExecute(params) {
  return request({
    url: "api/ads/template/execute",
    method: "post",
    data: params,
  });
}

export function httpTemplateGetMaterialPool(params) {
  return request({
    url: "/api/ads/template/getMaterialPool",
    method: "post",
    data: params,
  });
}

export function httpTemplateBatchPublishFbData(params) {
  return request({
    url: "api/ads/template/batchPublishFbData",
    method: "post",
    data: params,
  });
}

export function httpTemplateBatchPublishTtData(params) {
  return request({
    url: "/api/ads/tiktok/TiktokTlt/publish",
    method: "post",
    data: params,
  });
}

export function httpIftGetToken(params) {
  return request({
    baseURL: "https://new.inmad.cn/controller",
    url: "/ift/get_token.php",
    method: "post",
    data: params,
  });
}

export function httpShopifyUpload(params) {
  let formData = new FormData();
  formData.append("upfile", params.file);
  formData.append("order_item_id", params.id);
  return request({
    url: `/api/ads/report/Shopify/uploadImg?order_item_id=${params.id}`,
    method: "post",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function httpMaterialReportCreativeVideo(params) {
  return request({
    url: "api/ads/report/MaterialReport/creativeVideo",
    method: "post",
    data: params,
  });
}

export function httpChatgptImage(params) {
  return request({
    url: `sgpt/platform/chatgpt/xxh_image.php?prompt=${params.prompt}`,
    method: "post",
    data: params,
  });
}
export function httpAiQuestion(params) {
  return request({
    url: "api/ai/question/add",
    method: "post",
    data: params,
  });
}

export function httpAiQuestionList(params) {
  return request({
    url: "api/ai/question/list",
    method: "post",
    data: params,
  });
}

export function httpAiQuestionEdit(params) {
  return request({
    url: "api/ai/question/edit",
    method: "post",
    data: params,
  });
}

export function httpAiQuestionDelete(params) {
  return request({
    url: "api/ai/question/delete",
    method: "post",
    data: params,
  });
}

export function httpVirtualModel(params) {
  let p = { ...params };
  delete p?.json;
  return request({
    url: "/controller/bd/virtual_model.php",
    method: "POST",
    params: p,
    data: params,
  });
}

export function httpHumanAvatarList(params) {
  return request({
    url: "/api/ads/tiktok/human/avatarList",
    method: "POST",
    data: params,
  });
}
export function httpHumanAvatarCreate(params) {
  return request({
    url: "/api/ads/tiktok/human/avatarCreate",
    method: "POST",
    data: params,
  });
}

export function httpHumanGetTask(params) {
  return request({
    url: "/api/ads/tiktok/human/getTask",
    method: "POST",
    data: params,
  });
}

export function httpHumanVideoList(params) {
  return request({
    url: "/api/ads/tiktok/human/videoList",
    method: "POST",
    data: params,
  });
}

export function httpHumanAvatarShortList(params) {
  return request({
    url: "api/ads/tiktok/human/avatarShortList ",
    method: "POST",
    data: params,
  });
}

export function httpTikTokSparkList(params) {
  return request({
    url: "/api/ads/tiktok/spark/list",
    method: "POST",
    data: params,
  });
}
export function httpTikTokSparkLogLst(params) {
  return request({
    url: "api/ads/tiktok/spark/logLst",
    method: "POST",
    data: params,
  });
}

export function httpTikTokSparkLogExcelLst(params) {
  return request({
    url: "api/ads/tiktok/spark/logExcelLst",
    method: "POST",
    data: params,
  });
}

export function httpTikTokSparkImport(params) {
  return request({
    url: "/api/ads/tiktok/spark/import",
    method: "POST",
    data: params,
  });
}

export function httpTikTokSparkImportFile(params) {
  let formData = new FormData();
  formData.append("file", params.file);
  return request({
    url: `/api/ads/tiktok/spark/importFile`,
    method: "post",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function httpCharGPTUpload(params) {
  let formData = new FormData();
  formData.append("file", params.file);
  formData.append("open_id", params.open_id);
  formData.append("style", params.style);
  return request({
    baseURL: "https://beta.onemad.com/",
    url: `madapi/chatgpt/generate_img_comfyui.php`,
    method: "post",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function httpCharGPTHistory(params) {
  let formData = new FormData();
  formData.append("type", "history");
  formData.append("open_id", params.open_id);

  return request({
    baseURL: "https://beta.onemad.com/",
    url: `madapi/chatgpt/generate_img_comfyui.php`,
    method: "post",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

// snapchat 素材库

// snapchat 素材库

export function httpAdsLibraryList(params) {
  return request({
    url: "api/ads/library/list",
    method: "POST",
    data: params,
  });
}
export function httpQuestionUploadPic(params) {
  let formData = new FormData();
  formData.append("file", params.file);

  return request({
    url: "/api/ads/facebook/uploadPic",
    method: "post",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function httpCharGPTAiPaperwork(params) {
  let formData = new FormData();
  formData.append("pm_user_id", params.pm_user_id);
  formData.append("product", params.product);
  formData.append("brand_name", params.brand_name);

  return request({
    baseURL: "",
    url: `madapi/chatgpt/ai_paperwork.php`,
    method: "post",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}

export function httpTikToKTranslateUpload(params) {
  let formData = new FormData();
  
  formData.append("file", params.file);


  return request({
    url: `/api/ads/tiktok/translate/upload`,
    method: "post",
    data: formData,
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
}
